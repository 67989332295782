import React from "react"
import ElectricityMeterUnits from "../../../../components/body/pages/en-ng/business/bill-payments/electricity"
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

const ElectricityPaymentLanding = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/bill-payments/electricity-meter-units/"}
      title="Pay Electricity Bills | Prepaid Meter Recharge | Kuda for Business"
      description="Buy electricity units for PHED, AEDC, EEDC, EKDC, KEDCO, IBEDC, BEDC, JEDC, KAEDCO & IKEDC. Join Kuda for Business today."
    />
    <ElectricityMeterUnits />
  </Layout>
)

export default ElectricityPaymentLanding
