import React, { useEffect, Fragment } from "react"
import ImageDarkTextRightWithButton from "../components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "../components/imageDarkTextLeftWithButton"
import CTABusiness from "../../../general/ctaBusiness"
import FAQList from '../../../general/faqsList';
import InnerPageHeader from "../components/innerPageHeader"
import { scrollToElement } from "../../../../../utility/utils"
import DarkBelowFoldCards from "../../../general/darkBelowFoldCards";
import MoreForYouComponent from "../../../general/more-for-you";
import { moreForYou2 } from "../features";

// hero image
import EntryIllustration from '../../../../../../assets/img/electricity-hero-image.svg'
import EntryIllustrationBlur from '../../../../../../assets/img/business/blur/electricity.svg'

import BuyMeterUnitIcon from "../../../../../../assets/img/kuda-bulb-light-red.inline.svg"
import ColourMailIcon from "../../../../../../assets/img/kuda-letter-dark.inline.svg"
import EnvelopeIcon from '../../../../../../assets/img/kuda-envelope-dark.inline.svg'

import distributionIllustration from "../../../../../../assets/img/business/distribution_companies/distribution-illustration.svg"
import meterIllustration from "../../../../../../assets/img/business/meter_units/meter-illustration.svg"

import { Img } from "react-image";


const entryContent = {
    title: (<span>
        <span className="color-secondary">Recharge</span>  your prepaid electricity meter any time on the Kuda Business app.

    </span>),
    subtitle: "Meter top-ups for your business are super-convenient on Kuda. Buy units in seconds and get your token by email.",
    name: "Download Kuda Business",
    url: "https://kudabusiness.onelink.me/epwz/jceed731/",
}

const businessTopFeatures = [
    {
        icon: <BuyMeterUnitIcon />,
        text: "Buy meter units from any electricity distribution company."
    },
    {
        icon: <EnvelopeIcon />,
        text: "Get your meter token by email."
    },
    {
        icon: <ColourMailIcon />,
        text: "Top up your meter units any time."
    },
]

const electricityMeter = {
    title: "Running out of meter units at your office? Buy more on the Kuda Business app.",
    subtitle: " Kuda takes the stress out of recharging your prepaid meter whenever you need to.",
    name: "Open a Kuda Business Account",
    url: "https://kudabusiness.onelink.me/epwz/jceed731/",
    illustration: (
        <Img src={meterIllustration} className="kuda-business-image" />

    ),
}

const electricityDistribution = {
    title: "Find all major electricity distribution companies on the Kuda Business app.",
    subtitle: "Wherever you are, you can buy prepaid meter units for your business from any distribution company in Nigeria.",
    url: "https://kudabusiness.onelink.me/epwz/jceed731/",
    name: "Download Kuda Business",
    illustration: (

        <Img src={distributionIllustration} className="kuda-business-image" />

    ),
}


const questions = [
    {
        heading: "How do I recharge a prepaid meter on Kuda Business?",
        list: (
            <span className="flex flex-column  mt-2">
                <span className="faq-description f-16">To buy meter units on your Kuda Business app, follow these steps:</span>

                <span className="pt-3 career-message-margin-bottom ">
                    <div className="flex flex-column  leading-md-6 f-md-16">
                        <div className="mb-3">1. Sign in to your Kuda Business web or mobile app, then tap <b>Payments.</b></div>
                        <div className="mb-3">2. Tap <b>Pay A Bill.</b></div>
                        <div className="mb-3">3. Tap <b>Electricity.</b></div>
                        <div className="mb-3">4. Choose your electricity service provider (distribution company).</div>
                        <div className="mb-3">5. Choose the prepaid package.</div>
                        <div className="mb-3">6. Type in your prepaid meter number and the amount of meter units you want to buy.</div>
                        <div className="mb-3">7. Complete your payment with your transaction PIN, fingerprint or Face ID.</div>
                    </div>
                </span>

                <span>We’ll send your meter token to the email address linked to your Kuda Business account.</span>
            </span>),
        index: 1
    },
    {
        heading: "How do I make a postpaid meter payment on the Kuda Business app?",
        list: (
            <span className="flex flex-column  mt-2">
                <span className="faq-description f-16">To buy meter units on your Kuda Business app, follow these steps:</span>
                <span className="pt-3 career-message-margin-bottom ">
                    <div className="flex flex-column  leading-md-6 f-md-16">
                        <div className="mb-3">1. Sign in to your Kuda Business app, then tap <b>Payments.</b></div>
                        <div className="mb-3">2. Tap <b>Pay A Bill.</b></div>
                        <div className="mb-3">3. Tap <b>Electricity.</b></div>
                        <div className="mb-3">4. Choose your electricity service provider (distribution company).</div>
                        <div className="mb-3">5. Choose the postpaid package.</div>
                        <div className="mb-3">6. Type in your meter number and the amount of meter units you want to buy.</div>
                        <div className="mb-3">7. Complete your payment with your transaction PIN, fingerprint or Face ID.</div>
                    </div>
                </span>
                <span>We’ll send your meter token to the email address linked to your Kuda Business account.</span>
            </span>),
        index: 2
    },
    {
        heading: "Which electricity distribution companies can I buy meter tokens from on the Kuda Business app?",
        list: (
            <div className="flex flex-column  mt-2">
                <span className="faq-description mb-3 f-16">You can buy meter tokens from any of these companies on the Kuda app:
                </span>
                <div className="mb-3">AEDC - Abuja Electricity Distribution Company</div>
                <div className="mb-3">BEDC - Benin Electricity Distribution Company</div>
                <div className="mb-3">EEDC - Enugu Electricity Distribution Company</div>
                <div className="mb-3">EKEDC - Eko Electricity Distribution Company</div>
                <div className="mb-3">IBEDC - Ibadan Electricity Distribution Company</div>
                <div className="mb-3">IKEDC - Ikeja Electricity Distribution Company</div>
                <div className="mb-3">JEDC - Jos Electricity Distribution Company</div>
                <div className="mb-3">KAEDCO - Kaduna Electricity Distribution Company</div>
                <div className="mb-3">KEDCO - Kano Electricity Distribution Company</div>
                <div className="mb-3">PHED - Port Harcourt Electricity Distribution Company</div>
            </div>),
        index: 3
    },
    {
        heading: "How do I load my meter token?",
        list: (
            <div className="flex flex-column mt-2">
                <span className="faq-description f-16">To load your meter token, follow these steps:</span>
                <span className="pt-3 career-message-margin-bottom ">
                    <div className="flex flex-column  leading-md-6 f-md-16">
                        <div className="mb-3">1. Check your email for your meter token.</div>
                        <div className="mb-3">2. On your meter, type in the code on your token, then press the Enter button.</div>
                    </div>
                </span>
                <span>Your meter should be recharged immediately.</span>
            </div>),
        index: 4
    },
    {
        heading: "Why am I getting a ‘Reject’ error when I try to load a meter token?",
        list: (
            <div className="flex flex-column mt-2">
                <span className="faq-description f-16">You might be getting a ‘Reject’ error on your meter for any of these reasons:</span>
                <span className="pt-3 career-message-margin-bottom ">
                    <div className="flex flex-column  leading-md-6 f-md-16">
                        <div className="mb-3 text-bold">1. Your meter number is wrong.
                            <span className="flex mb-3 text-normal mt-3">
                                You might have typed in the wrong meter number when you were buying meter units on your Kuda app.</span>

                            <span className="flex text-normal">To confirm that you typed in the right meter number,
                                check the transaction receipt on your Kuda dashboard or check your transaction notification email.</span>
                        </div>

                        <div className="mb-3 text-bold">2. Your meter hasn’t been activated.
                            <span className="flex mb-3 text-normal mt-3">
                                Every new meter has to be activated before it can accept tokens.</span>

                            <span className="flex text-normal">To activate your meter, ask your electricity distribution company
                                for an activation code and type in the code on your meter. After this, your meter will accept tokens.</span>
                        </div>

                        <div className="mb-3 text-bold">3. Your meter smart card isn’t working.

                            <span className="flex text-normal mt-3">
                                If you use a smart card meter, the chip on the card might be dirty. To clean the chip, remove the smart card from your meter,
                                wipe it thoroughly with a dry piece of cloth, then put the smart card back into your meter and try to load the token.</span>

                        </div>

                        <div className="mb-3 text-bold">4. Your meter isn’t properly connected.

                            <span className="flex text-normal mt-3">Your meter could be on but not properly connected. To fix this, reach out to your electricity distribution company.</span>

                        </div>

                        <div className="mb-3 text-bold">5. Your meter is in tamper mode.

                            <span className="flex text-normal mt-3">If your meter is in tamper mode, reach out to your electricity distribution company
                                to get a tamper token, load the token, then try to recharge your meter.
                            </span>

                        </div>
                        <div className="mb-3 text-bold">6. Your electricity tariff plan has changed.

                            <span className="flex mb-3 text-normal mt-3">If the tariff plan in your location has changed, you won’t be
                                able to recharge your meter until it has been reconfigured for the new tariff plan.
                            </span>
                            <span className="flex mb-3 text-normal">To reconfigure your meter, reach out to your electricity distribution company
                                to get a Key Change Token (KTC) which you’ll load on your meter. After this, you’ll be able to recharge your meter.
                            </span>
                        </div>

                    </div>
                </span>

            </div>),
        index: 5
    },

    {
        heading: "I’m getting a ‘Link’ error on my meter. What does this mean?",
        list: (
            <span className="flex flex-column mt-2">
                <span className="faq-description f-16">A ‘Link’ error means your meter isn’t connecting to your interface unit (the unit with the keypad).
                    This might be happening for any of these reasons:</span>

                <span className="pt-3 career-message-margin-bottom ">
                    <div className="flex flex-column  leading-md-6 f-md-16">
                        <div className="mb-3 text-bold">1. Your meter doesn’t have power supply.
                            <span className="flex text-normal mt-3">
                                To fix this, check if there’s electricity in your location. If there’s electricity,
                                check that the phase you’re in has power supply. Make sure your generator or inverter is off,
                                if you own one. Once your meter has power supply, you can load your token.
                            </span>
                        </div>

                        <div className="mb-3 text-bold">2. The batteries in your interface unit (keypad) are too weak.
                            <span className="flex text-normal mt-3">
                                To fix this, replace the weak batteries with new ones, then turn on the unit if it doesn’t come back on automatically..</span>
                        </div>

                        <div className="mb-3">3. <b>You need to reset your interface unit (keypad).</b>

                            <span className="flex faq-description f-16 ">To reset the interface unit (keypad), follow these steps:</span>

                            <span className="pt-3 career-message-margin-bottom mt-2">
                                
                                <div className="flex flex-column  leading-md-6 f-md-16 mt-3"> 
                                    <div className="mb-3 text-normal">1. If your meter still has units, plug the interface unit (keypad) into a 13-amp socket - usually the socket for an AC, freezer or electric cooker - then turn on the unit.

                                    </div>
                                    <div className="mb-3 text-normal">2.
                                        Replace the first four digits of your meter number with ‘5258’, then type the new number into your interface unit (keypad) and press the ‘Enter’ button.

                                        <span className="mt-3 flex text-normal">For example, if your meter number is 123456789, you’ll replace ‘1234’ with ‘5258’, so the new number you’ll type in will be 525856789</span>
                                    </div>
                                    <div className="mb-3 text-normal">3. Load your meter token like you normally would.</div>
                                </div>
                            </span>

                        </div>
                    </div>
                </span>
            </span>),
        index: 6
    },
]

const ElectricityMeterUnits = () => {
    useEffect(() => {
        scrollToElement()
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, [])

    return (

        <Fragment>

            <InnerPageHeader
                title={entryContent.title}
                subtitle={entryContent.subtitle}
                buttonName={entryContent.name}
                buttonUrl={entryContent.url}
                entryIllustration={EntryIllustration}
                fallback={<Img src={EntryIllustrationBlur} className="hero-illustration"/>}
            />
            <DarkBelowFoldCards topFeatures={businessTopFeatures} />
            <ImageDarkTextLeftWithButton
                title={electricityMeter.title}
                subtitle={electricityMeter.subtitle}
                illustration={electricityMeter.illustration}
                name={electricityMeter.name}
                url={electricityMeter.url}
                isExternal={true}
            />
            <ImageDarkTextRightWithButton
                title={electricityDistribution.title}
                subtitle={electricityDistribution.subtitle}
                illustration={electricityDistribution.illustration}
                name={electricityDistribution.name}
                url={electricityDistribution.url}
                isExternal={true}
            />


            <FAQList title={"Electricity Payments FAQs"} questions={questions} />
            <MoreForYouComponent moreForYou={moreForYou2}/>
            <CTABusiness />
        </Fragment>
    )
}

export default ElectricityMeterUnits

